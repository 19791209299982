import '/assets/styles/sections/listing-subcategories.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'

// Partials
import Simplebar from '/views/partials/simplebar';
import Placeholder from '/views/partials/placeholder';
import Link from 'pstv-commerce-tools/partials/link';

const ListingSubcategories = ({ subcategories }) => {
	const data = useMemo(() => {
		return subcategories !== undefined ? (subcategories ?? [false, false, false, false, false, false, false, false]) : false;
	}, [subcategories])

	if(data && data.length > 0) {
		return (
			<nav className="section listing-subcategories">
				<div className="subcategories-wrap wrapper">
					<div className="subcategories-outerwrap">
						<Simplebar className="subcategories-sliderwrap">
							<ul className="subcategories-list">
								{data.map((category, nth) => (
									<li className="list-subcategory" key={category ? category.id : `placeholder-${nth}`}>
										{category ?
											<Link
												key={category.id}
												className="subcategory-link"
												href="category"
												params={{
													id: category.id,
													slug: category.slug ?? kebabCase(category.name)
												}}>
												{category.name}
											</Link>
											:
											<Placeholder
												key={`placeholder-${nth}`}
												className="subcategory-link" />
										}
									</li>
								))}
							</ul>
						</Simplebar>
					</div>
				</div>
			</nav>
		)
	}
	else {
		return null;
	}
}

ListingSubcategories.propTypes = {
	subcategories: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false,null])]),
}

export default ListingSubcategories;
