import '/assets/styles/pages/category.scss';

import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'
import categoryFilters from '/data/listing-filters/types/category'

// Services
import productServices from '/services/product';

// Functions
import findCategory from 'pstv-commerce-tools/functions/find-category';
import isExact from 'pstv-commerce-tools/functions/is-exact';

// Sections
import ListingHead from '/views/sections/listing-head';
import Listing from '/views/sections/listing';
import ListingSubcategories from '/views/sections/listing-subcategories';
import Breadcrumbs from '/views/sections/breadcrumbs';

// Context
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useHead } from 'pstv-commerce-tools/utilities/head';
import { ListingProvider, decodeQueryString, useListing } from 'pstv-commerce-tools/utilities/listing';
import ListingFilters from '../sections/listing-filters';

const getCategoryProducts = (categoryId, query) => {
	return productServices.getCategoryProducts(categoryId, query)
}

const parseHead = (categoryInfo) => {
	const title = categoryInfo.seo_title || categoryInfo.name;
	return {
		title: title,
		description: categoryInfo.description || `${title} ürünleri.`,
	}
}

const defaultQuery = {
	page: '1',
	// order: 'ovd',
}

const CategoryQueryProvider = ({match, ...props}) => {
	return (
		<ListingProvider
			externalParams={{ categoryID: match?.params?.id }}
			availableFilters={categoryFilters}
			initialFilterData={props.pageProps?.filters}
			queryString={match?.params?.query}
			defaultQuery={defaultQuery}>
			<Category {...props} />
		</ListingProvider>
	)
}

const Category = ({ pageProps }) => {
	const { inputQuery, outputData, setFilterData } = useListing();
	const { redirect } = useNavigator();

	const { setHead } = useHead();
	const { categories, setNotFound } = useGlobalState();
	const [{products, pagination, fetchedQuery}, setProducts] = useState(pageProps?.productData ?? {products: null, fetchedQuery: null, pagination: null});

	const activeCategory = useMemo(() => {
		if(categories && inputQuery.categoryID) {
			return findCategory(categories, inputQuery.categoryID);
		}
		else {
			return false;
		}
	}, [categories, inputQuery.categoryID]);

	useEffect(() => {
		if(activeCategory) {
			setHead(parseHead(activeCategory));
		}
	}, [activeCategory])

	useEffect(() => {
		if(inputQuery.categoryID && !isExact(fetchedQuery, inputQuery)) {
			const { categoryID, ...queryParams } = inputQuery;
			setProducts({ products: null, pagination: null, fetchedQuery: inputQuery });
			getCategoryProducts(categoryID, queryParams).then(productData => {
				setProducts({ ...productData, fetchedQuery: inputQuery});
				setFilterData(productData.filters);
			});
		}
	}, [inputQuery, fetchedQuery])

	useEffect(() => {
		if(activeCategory) {
			if(activeCategory.id.toString() === outputData?.externalParams?.categoryID) {
				const params = {
					id: outputData.externalParams.categoryID,
					slug: activeCategory.slug ?? kebabCase(activeCategory.name)
				};

				if(outputData.queryString && outputData.queryString.length) {
					params.query = outputData.queryString;
				}
				redirect('category', params)
			}
		}
		else {
			setNotFound();
		}
	}, [outputData, activeCategory])


	return (
		<>
			<Breadcrumbs data={activeCategory.breadcrumb} />
			<ListingHead title={activeCategory?.name} totalCount={pagination?.total} />
			<ListingSubcategories subcategories={activeCategory.children} />
			<ListingFilters />
			<Listing
				products={products}
				pagination={pagination} />
		</>
	)
}

Category.propTypes = {
	pageProps: PropTypes.object
}

CategoryQueryProvider.getServerProps = ({ match, sharedData }) => {
	return new Promise((resolve) => {
		const rawQuery = decodeQueryString({
			availableFilters: categoryFilters,
			queryString: match.params.query,
			defaultQuery,
			externalParams: { categoryID: match.params.id }
		});

		const { categoryID, ...query } = rawQuery;
		Promise.all([
			getCategoryProducts(categoryID, query)
		]).then(([productData]) => {
			const categoryInfo = findCategory(sharedData.categories, categoryID);

			resolve({
				pageProps: {
					filters: productData.filters,
					productData: {
						...productData,
						fetchedQuery: rawQuery,
					},
				},
				head: parseHead(categoryInfo),
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

CategoryQueryProvider.propTypes = {
	match: PropTypes.object,
	pageProps: PropTypes.object,
}

export default CategoryQueryProvider
