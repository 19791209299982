import defaultFilter from '../default-filter.js'

const categoryFilters = {
	brands: {
		...defaultFilter,
		title: 'Marka',
		singleTitle: 'Marka',
		valueFormat: 'array',
		searchable: true,
		searchPlaceholder: 'Marka ara',
		listInSummary: true,
	},
	price_range: {
		...defaultFilter,
		filterProcessor: 'range',
		title: 'Fiyat',
		singleTitle: 'Fiyat',
		minKey: 'min_price',
		maxKey: 'max_price',
		options: true,
		optionPostfix: 'TL',
		customEnabled: true,
		listInSummary: true,
		affectedFields: ['min_price', 'max_price'],
	},

	// Unlisted / Custom
	categoryID: {
		...defaultFilter,
		filterProcessor: false,
	},
	page: {
		...defaultFilter,
		filterProcessor: false,
	},
	order: {
		...defaultFilter,
		filterProcessor: false,
	},
	min_price: {
		...defaultFilter,
		filterProcessor: false,
	},
	max_price: {
		...defaultFilter,
		filterProcessor: false,
	},
	metro_brand: {
		...defaultFilter,
		filterProcessor: false,
	},
	campaign: {
		...defaultFilter,
		filterProcessor: false,
	},
	stock: {
		...defaultFilter,
		filterProcessor: false,
	},
}

export default categoryFilters